
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";

import { Supply } from "@/core/interfaces/Models";
import { VatTuService } from "@/core/services/VatTu.service";
import {formatCurrencyAndQuantity, getErrorMsg, swalNotification} from '@/core/helpers/utils';
import { SupplyMetaItemResponse } from "@/core/interfaces/ApiResponses";
import { SupplyMetaService } from "@/core/services/SupplyMeta.service";
// import SupplyMetaList from "@/views/crafted/pages/vat-tu/supply-meta-list/SupplyMetaList.vue";
// import SupplyMetaForm from "@/views/crafted/pages/vat-tu/supply-meta-form/SupplyMetaForm.vue";
import {setCurrentPageTitle} from '@/core/helpers/breadcrumb';
import {useRouterCustom} from '@/core/hooks/use-router-custom';
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import ConfirmationDialog from '@/components/confirmation-dialog/ConfirmationDialog.vue';
import {useLoading} from '@/core/hooks/use-loading';

export default defineComponent({
  name: "supply",
  components: {
		ButtonCustom,
		ConfirmationDialog,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const isCreate = ref(true);
		const { goBack, push } = useRouterCustom()
		const { startLoading, endLoading } = useLoading();

    const isInaccessible = computed(() => {
      const routeName = route.name;

			if (routeName === 'update-supply') return process.env.VUE_APP_IS_EDIT_VAT_TU_ENABLE === 'false';

			return process.env.VUE_APP_IS_ADD_VAT_TU_ENABLE === 'false';
		});

    const supply = ref<Supply>({
      id: 0,
      name: '',
      year: 0,
      slug: '',
      minimumReserve: 0,
      maximumReserve: 0,
      recall: false,
      updatedAt: '',
      createdAt: '',
      isApprovedByComp: false,
      quantity: 0,
      unitString: '',
      price: 0,
      code: '',
      accountId: 0,
      tenTaiKhoan: '',
      supplyMetaItems: [] as SupplyMetaItemResponse[],
    });

    const updateSupplyMeta = ref<{
      id: number;
      key: string;
      title: string;
      value: string;
    }>({
      id: 0,
      key: '',
      title: '',
      value: '',
    });

    const fetchedSupplyDetail = async () => {
			startLoading();
      try {
        const {
          data: {
            data: {
              id,
              ten_vat_tu,
              ma_vat_tu,
              // id_tai_khoan,
              muc_du_tru_toi_da,
              muc_du_tru_toi_thieu,
              updated_at,
              don_gia,
              nam,
              so_luong,
              don_vi_tinh,
              thu_hoi,
              created_at,
              cong_ty_duyet,
              // vat_tu_meta,
              // ten_tk,
            },
          },
        } = await VatTuService.fetchSupplyDetail(+route.params.id);
        supply.value.id = id;
        supply.value.name = ten_vat_tu;
        supply.value.code = ma_vat_tu;
        supply.value.quantity = so_luong;
        supply.value.unitString = don_vi_tinh;
        supply.value.price = don_gia;
        supply.value.minimumReserve = muc_du_tru_toi_thieu;
        supply.value.maximumReserve = muc_du_tru_toi_da;
        // supply.value.accountId = id_tai_khoan;
        supply.value.year = nam;
        supply.value.recall = thu_hoi;
        supply.value.isApprovedByComp = cong_ty_duyet;
        // supply.value.supplyMetaItems = [...vat_tu_meta];
        // supply.value.tenTaiKhoan = ten_tk || '';
        supply.value.createdAt = moment(created_at).format("h:mm, DD/MM/YYYY");
        supply.value.updatedAt = moment(updated_at).format("h:mm, DD/MM/YYYY");
				endLoading();
      } catch (e) {
				endLoading();
				await swalNotification(
					getErrorMsg(e, 'Xin lỗi, có vẻ có lỗi xảy khi lấy thông tin vật tư, mời bạn thực hiện lại'),
					'error',
				);
        await router.push({ path: "/trang-chu" });
      }
    };

    onMounted(async () => {
			setCurrentPageTitle('Vật tư');
      await fetchedSupplyDetail();
    });

    const onCreatedOrUpdateSuccessHandler = async () => {
      isCreate.value = true;
      updateSupplyMeta.value.id = 0;
      updateSupplyMeta.value.key = '';
      updateSupplyMeta.value.title = '';
      updateSupplyMeta.value.value = '';
      await fetchedSupplyDetail();
    };

    const onCancellingEditing = () => {
      isCreate.value = true;
      updateSupplyMeta.value.id = 0;
      updateSupplyMeta.value.key = '';
      updateSupplyMeta.value.title = '';
      updateSupplyMeta.value.value = '';
    };

    const onEditIconClickHandler = (
      id: number,
      key: string,
      title: string,
      value: string
    ) => {
      isCreate.value = false;
      updateSupplyMeta.value.id = id;
      updateSupplyMeta.value.key = key;
      updateSupplyMeta.value.title = title;
      updateSupplyMeta.value.value = value;
    };

    const onDeleteIconClickHandler = async (id: number) => {
      try {
        await SupplyMetaService.deleteSupplyMeta(id);
        await fetchedSupplyDetail();
      } catch (error) {
				await swalNotification(
					'Xin lỗi, có vẻ có lỗi xảy ra, mời bạn thực hiện lại',
					'error'
				);
      }
    };

    return {
      supply,
      formatCurrencyAndQuantity,
      formatDate: computed(() => moment),
      isInaccessible,
      onEditIconClickHandler,
      onCreatedOrUpdateSuccessHandler,
      updateSupplyMeta,
      onDeleteIconClickHandler,
      isCreate,
      onCancellingEditing,
			goBack,
			push,
    };
  },

	data() {
		return {
			isDeleteVatTuDialogDisplay: false,
		}
	},

	methods: {
		onDeleteVatTuClicked() {
			this.isDeleteVatTuDialogDisplay = true;
		},

		async confirmDelete() {
			this.isDeleteVatTuDialogDisplay = false;

			try {
				await VatTuService.delete(this.supply.id);
				await swalNotification(
					'Xóa vật tư thành công',
					'success'
				);
				await this.push('/quan-ly/vat-tu/danh-sach');
			} catch {
				await swalNotification(
					'Có lỗi xảy ra, không thể xóa vật tư này',
					'error'
				);
			}
		}
	}
});
