import {
  ApiResponse,
  MessageItemResponse,
  SupplyMetaItemResponse,
} from "@/core/interfaces/ApiResponses";
import { apiServiceInstance } from "@/core/helpers/utils";

export class SupplyMetaService {
  static addNewSupplyMeta(
    supplyId: number,
    key: string,
    title: string,
    value: string
  ): Promise<{
    data: ApiResponse<MessageItemResponse | SupplyMetaItemResponse>;
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";
    const bodyFormData = new FormData();
    bodyFormData.append("vatTuMetaInstance[id_vat_tu]", supplyId.toString());
    bodyFormData.append("vatTuMetaInstance[meta_title]", title);
    bodyFormData.append("vatTuMetaInstance[meta_key]", key);
    bodyFormData.append("vatTuMetaInstance[meta_value]", value);
    return apiServiceInstance().vueInstance.axios.post(
      "/api/v1/vat-tu-meta/tao-moi",
      bodyFormData
    );
  }

  static updateSupplyMeta(
    id: number,
    key: string,
    title: string,
    value: string
  ): Promise<{
    data: ApiResponse<Partial<MessageItemResponse & SupplyMetaItemResponse>>;
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";
    const bodyFormData = new FormData();
    bodyFormData.append("vatTuMetaInstance[meta_title]", title);
    bodyFormData.append("vatTuMetaInstance[meta_key]", key);
    bodyFormData.append("vatTuMetaInstance[meta_value]", value);
    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/vat-tu-meta/cap-nhat/${id}`,
      bodyFormData
    );
  }

  static deleteSupplyMeta(id: number) {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      "Content-Type"
    ] = "multipart/form-data";
    return apiServiceInstance().vueInstance.axios.post(
      `/api/v1/vat-tu-meta/xoa/${id}`
    );
  }
}
